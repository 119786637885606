
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Code"
              label-for="basicInput"
            >

             <validation-provider
              #default="{ errors }"
              name="Code"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.code"
                :plaintext="readOnly"
                placeholder="Code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Lunettes"
              label-for="Type de verre"
            >
            
              <v-select
                v-model="form.products"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
               
                
                :options="productsChoices"
              />
              
            </b-form-group>
          </b-col>
         
          

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Montant"
              label-for="Prix"
            >
            
         
              <v-select
                v-model="form.amount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
               
                
                :options="percentageChoices"
              />
              
            </b-form-group>
          </b-col>

           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Actif"
              label-for="Actif"
            >
              <b-form-checkbox
                v-model="form.active"
                
                class="custom-control-primary"
              >
                Actif ?
              </b-form-checkbox>
            </b-form-group>
          </b-col>

       
    

           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Limite d'usage (général)"
              label-for="Prix"
            >
              <b-form-input
                id="Prix"
                v-model="form.usage_limit"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Limite d'usage (général)"
              />
            </b-form-group>
          </b-col>

          


        </b-row>
      </b-form>
      </validation-observer>

    </b-card>

      <b-button v-if="edition==false"
     @click="createPromotion"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="editPromotion"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BForm, BRow, BOverlay,BFormDatepicker, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils} from '@simb2s/senseye-sdk'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'PromotionForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormDatepicker
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          loading:true,
          products:[],
          productsChoices:[],
          productsCategoriesChoices: [
            {
              title: 'Collection',
              value: 'collection',
            },
            {
              title: 'Collaboration',
              value: 'collaboration',
            }
          ],
          typeChoices: [
            {
              label: 'Remise fixe (en €)',
              value: 'fixed',
            },
            {
              label: 'Pourcentage',
              value: 'percentage',
            }
          ],
          edition:false,
          percentageChoices:[
            {
              title:'100%',value:100
            },
            {
              title:'90%',value:90
            },
            {
              title:'80%',value:80
            },
            {
              title:'70%',value:70
            },
            {
              title:'60%',value:60
            },
            {
              title:'50%',value:50
            },
            {
              title:'40%',value:40
            },
            {
              title:'30%',value:30
            },
            {
              title:'20%',value:20
            },
            {
              title:'10%',value:10
            }
            
            ],
          form:{
            name:"",
            code:'',
            user:{id:localStorage.id_user_senseye_auth},
            amount:0,
            promotion_type:'creator',
            active:true,
            type:'percentage',
            use_count:0,
            free_shipping:false,
            end_date:null,
            minimum_spend:null,
            maximum_spend:null,
            individual_use:true,
            exclude_sale_items:false,
            products:null,
            product_categories:null,
            allowed_emails:null,
            usage_limit:null,
            user_limit:1
          },
      }
  },
  props: ['promotion', 'readOnly'],
  methods:{
    async getProducts() {
      let products = await APIRequest.list_with_request('glasses', '?creator_owner='+localStorage.id_user_senseye_auth)

      
      this.products=products[0];
      this.productsChoices=Utils.formatSelect(this.products,'name','id')
     
    },
    async createPromotion()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
          let datas=this.form
          this.form.products=[{id:this.form.products.value}]
          this.form.amount=this.form.amount.value
          await APIRequest.create('promotions',datas)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La promotion a bien été créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'promotions'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
   async editPromotion()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {

          let datas=this.form
          this.form.products=[{id:this.form.products.value}]
          this.form.amount=this.form.amount.value
          
          await APIRequest.update('promotions',this.promotion.id,datas)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La promotion a bien été modifiée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'promotions'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    
     
      
  },
  async mounted(){
    await this.getProducts()
    if(this.promotion!=null)
    {
      this.form=this.promotion
      if(this.promotion.products.length>0)
      {   console.log('type de promo')
      console.log(this.form.type)

      for(let i=0;i<this.productsChoices.length;i++)
    {
      if(this.promotion.products[0].id==this.productsChoices[i].value)
    {
      this.form.products=this.productsChoices[i]
    }
    }
        
      }
      console.log(this.form)
      this.file=this.promotion.photo
      this.edition=true
   
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>